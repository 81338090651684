<template>
  <div class="mainPage">
    <div class="AnchorPointsDiv">
      <AnchorPoints :count="totalPages" :nowIndex="nowActiveAnchor" @anchorPointClick="changePage"></AnchorPoints>
    </div>
    <!-- <div class="nav">
      <ul>
        <li>
          <a href="https://www.baidu.com">关于我们</a>
        </li>
        <li>
          <a href="#test3">常见问题</a>
        </li>
      </ul>
    </div>-->
    <div class="scrollDiv" :style="scrollDivStyle" tabindex="1">
      <div class="section section_0">
        <div class="bgVideo">
          <video muted loop autoplay ref="videoRef">
            <source src="@/assets/1.mp4" type="video/mp4" />
          </video>
        </div>
        <div class="content">
          <div class="centerContent">
            <!-- <div class="title_item" :class="{ title_item_moved_top_200: pageParams[0].showAnimate }">
              <img style="height: 58px" src="../assets/title.svg" />
            </div> -->
            <div class="title_item" :class="{ title_item_moved_top_300: pageParams[0].showAnimate }">
              <h1 id="aassdd" style="
                  font-weight: 500;
                  margin-top: 5%;
                  font-size: 70px;
                  letter-spacing: 3.5px;
                ">让学车更加简单</h1>
              <h3 style="
                  font-weight: 100;
                  margin-top: 2%;
                  font-size: 25px;
                  letter-spacing: 1.25px;
                ">智能驾培 赋能升级</h3>
            </div>
            <div class="title_item" :class="{ title_item_moved_bottom: pageParams[0].showAnimate }"
              style="margin-top: 10%">
              <img style="height: 140px" src="../assets/code.png" />
              <p style="margin-top: 1%; font-size: 18px">扫一扫</p>
            </div>
          </div>
        </div>
      </div>
      <div class="section section_1">
        <h1 class="main_title">拥有强大互联网基础的领先AI公司</h1>
        <h3 class="main_describe">以创新技术为信仰，为帮助全球用户创造更加美好生活而不断努力</h3>
        <div class="item_cards">
          <div class="item_card">
            <p style="font-size: 32px; letter-spacing: 1.6px; font-weight: 700">3年蝉联第一</p>
            <p style="font-size: 24px; letter-spacing: 1.2px; margin-top: 23px">人工智能专利</p>
            <div class="br_line">
              <div class="point"></div>
              <div class="right_line"></div>
            </div>
            <img src="../assets/code.png" alt style="width: 196px; height: 200px" />
            <p style="
                width: 200px;
                font-size: 22px;
                letter-spacing: 1.1px;
                margin: 33px auto 0 auto;
              ">发布搜索服务智能机器人助手</p>
          </div>
          <div class="item_card">
            <p style="font-size: 32px; letter-spacing: 1.6px; font-weight: 700">50% 智能专利</p>
            <p style="font-size: 24px; letter-spacing: 1.2px; margin-top: 23px">人工智能专利</p>
            <div class="br_line">
              <div class="point"></div>
              <div class="left_line"></div>
              <div class="right_line"></div>
            </div>
            <img src="../assets/code.png" alt style="width: 196px; height: 200px" />
            <p style="
                width: 200px;
                font-size: 22px;
                letter-spacing: 1.1px;
                margin: 33px auto 0 auto;
              ">发布搜索服务智能机器人助手</p>
          </div>
          <div class="item_card">
            <p style="font-size: 32px; letter-spacing: 1.6px; font-weight: 700">50% 智能专利</p>
            <p style="font-size: 24px; letter-spacing: 1.2px; margin-top: 23px">人工智能专利</p>
            <div class="br_line">
              <div class="point"></div>
              <div class="left_line"></div>
              <div class="right_line"></div>
            </div>
            <img src="../assets/code.png" alt style="width: 196px; height: 200px" />
            <p style="
                width: 200px;
                font-size: 22px;
                letter-spacing: 1.1px;
                margin: 33px auto 0 auto;
              ">发布搜索服务智能机器人助手</p>
          </div>
          <div class="item_card">
            <p style="font-size: 32px; letter-spacing: 1.6px; font-weight: 700">50% 智能专利</p>
            <p style="font-size: 24px; letter-spacing: 1.2px; margin-top: 23px">人工智能专利</p>
            <div class="br_line">
              <div class="point"></div>
              <div class="left_line"></div>
              <div class="right_line"></div>
            </div>
            <img src="../assets/code.png" alt style="width: 196px; height: 200px" />
            <p style="
                width: 200px;
                font-size: 22px;
                letter-spacing: 1.1px;
                margin: 33px auto 0 auto;
              ">发布搜索服务智能机器人助手</p>
          </div>
          <div class="item_card">
            <p style="font-size: 32px; letter-spacing: 1.6px; font-weight: 700">50% 智能专利</p>
            <p style="font-size: 24px; letter-spacing: 1.2px; margin-top: 23px">人工智能专利</p>
            <div class="br_line">
              <div class="point"></div>
              <div class="left_line"></div>
            </div>
            <img src="../assets/code.png" alt style="width: 196px; height: 200px" />
            <p style="
                width: 200px;
                font-size: 22px;
                letter-spacing: 1.1px;
                margin: 33px auto 0 auto;
              ">发布搜索服务智能机器人助手</p>
          </div>
        </div>
      </div>
      <div class="section section_2">
        <h1 class="main_title">技术赋能-源自智能驾培底层技术</h1>
        <h3 class="main_describe">以创新技术为信仰，为帮助全球用户创造更加美好生活而不断努力</h3>
        <div class="item_cards">
          <div class="item_card">
            <img src="../assets/page2_1.png" alt style="width: 100%" />
            <p class="bottom_main_title">高精定位</p>
            <p class="bottom_describe">多传感器融合定位，平均定位精度3-5cm</p>
          </div>
          <div class="item_card">
            <img src="../assets/page2_2.png" alt style="width: 100%" />
            <p class="bottom_main_title">全方位感知系统</p>
            <p class="bottom_describe">多传感器融合感知，感知可视化</p>
          </div>
          <div class="item_card">
            <img src="../assets/page2_3.png" alt style="width: 100%" />
            <p class="bottom_main_title">决策控制</p>
            <p class="bottom_describe">规划决策安全、高效、舒适</p>
          </div>
          <div class="item_card">
            <img src="../assets/page2_4.png" alt style="width: 100%" />
            <p class="bottom_main_title">高精地图</p>
            <p class="bottom_describe">精度厘米级、要素精细化</p>
          </div>
        </div>
      </div>
      <div class="section section_3">
        <h1 class="main_title">数字化+网联化+智能化+自动化</h1>
        <h3 class="main_describe">以提升学员满意度为核心，通过科技降本增效，开源增收</h3>
        <div class="content">
          <div class="card" id="card_left">
            <p class="title">传统驾校</p>
            <div class="desctibe_items">
              <p class="p_itemStyle">培训质量难统一</p>
              <p class="p_itemStyle">培训质量难规范</p>
              <p class="p_itemStyle" style="margin-top: 40px">存在事故安全隐患</p>
              <p class="p_itemStyle">教学场景单一</p>
              <p class="p_itemStyle" style="margin-top: 40px">数字化管理缺少技术支持</p>
              <p class="p_itemStyle" style="margin-top: 40px">品牌影响力小</p>
              <p class="p_itemStyle">运营效率低</p>
            </div>
          </div>
          <div class="card" id="card_right">
            <p class="title">智慧驾校</p>
            <div class="desctibe_items">
              <div class="desctibe_item" style="padding-right: 50px">
                <p class="title">培训更高效</p>
                <div class="items">
                  <p class="p_itemStyle">依托智能教学辅助系统，规范科目教学 流程,科技赋能，多元协同</p>
                  <p class="p_itemStyle" style="margin-top: 33px">打破“一对一”人工教学模式，借助智能教学辅助系统与远程监管系统的结合，实现“一对多”高效培训</p>
                </div>
              </div>
              <div class="desctibe_item" style="padding-left: 50px">
                <p class="title">驾驶更安全</p>
                <div class="items">
                  <p class="p_itemStyle">依托自动驾驶技术，实现教学车辆安全培训</p>
                </div>
              </div>
              <div class="desctibe_item" style="padding-right: 50px">
                <p class="title">管理更精细</p>
                <div class="items">
                  <p class="p_itemStyle">教学管理信息整合同步，管理数据多维分析，提升驾校对人、车、校的全面管理效率及效果</p>
                  <p class="p_itemStyle" style="margin-top: 33px">驾培行业管理精细化，理念可推行、标准可落地</p>
                </div>
              </div>
              <div class="desctibe_item" style="padding-left: 50px">
                <p class="title">运营更科学</p>
                <div class="items">
                  <p class="p_itemStyle">通过线下智能教学培训，线上品牌生态推广，实现驾校口碑提升大数</p>
                  <p class="p_itemStyle" style="margin-top: 33px">据分析为驾校经营提供决策依据，降低管理成本</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section_4">
        <h1 class="main_title">智能教学</h1>
        <h3 class="main_describe">移动端视频教学与智能教练车实训结合，提供线上+线下多维教学体验</h3>
        <div class="content">
          <div class="card" style="width: 700px">
            <p class="title">ONLINE线上</p>
            <div class="items">
              <p class="p_itemStyle">手机端训练课程视频教学</p>
              <p class="p_itemStyle">训练结果实时统计，训练数据一键回收</p>
              <div class="imgDiv" style="
                  margin-top: 36px;
                  height: 518px;
                  display: flex;
                  align-items: center;
                ">
                <img src="../assets/page4_1.png" alt style="height: 100%" />
              </div>
            </div>
          </div>
          <div class="card" style="width: 700px">
            <p class="title">OFFLINE线下</p>
            <div class="items">
              <p class="p_itemStyle">自动化培训指导，模拟真实教学完整流程</p>
              <p class="p_itemStyle">交互式体验，学员轻松，高效记忆练车要点</p>
              <div class="imgDiv" style="
                  margin-top: 36px;
                  height: 518px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                <img src="../assets/page4_2.png" alt style="width: 100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section_5">
        <h1 class="main_title">智能教学</h1>
        <div class="content">
          <div class="card" style="width: 320px">
            <div class="title_card">
              <p style="
                  text-align: left;
                  font-size: 36px;
                  font-weight: 700;
                  letter-spacing: 1.8px;
                ">STEP1</p>
              <p style="
                  margin-top: 16px;
                  text-align: left;
                  font-size: 24px;
                  font-weight: 300;
                  letter-spacing: 1.2px;
                ">基础驾驶训练</p>
              <p style="
                  margin-top: 4px;
                  text-align: left;
                  font-size: 28px;
                  font-weight: 700;
                  letter-spacing: 1.4px;
                ">识部件、悟车感</p>
            </div>
            <p class="p_itemStyle" style="margin-top: 80px">为学员提供车辆基础部件的操作教学，满足学员上车后的初学体验</p>
            <p class="p_itemStyle" style="margin-top: 30px">同时，基础驾驶能力的训练为学员进一步寻找车感提供帮助</p>
          </div>
          <div class="card" style="width: 200px">
            <img src="../assets/page5_1.png" alt style="margin-top: 50px; width: 50px" />
          </div>
          <div class="card" style="width: 320px">
            <div class="title_card">
              <p style="
                  text-align: left;
                  font-size: 36px;
                  font-weight: 700;
                  letter-spacing: 1.8px;
                ">STEP2</p>
              <p style="
                  margin-top: 16px;
                  text-align: left;
                  font-size: 24px;
                  font-weight: 300;
                  letter-spacing: 1.2px;
                ">科目教学训练</p>
              <p style="
                  margin-top: 4px;
                  text-align: left;
                  font-size: 28px;
                  font-weight: 700;
                  letter-spacing: 1.4px;
                ">多模式、进阶练习</p>
            </div>
            <p style="
                height: 80px;
                line-height: 80px;
                text-align: left;
                font-size: 24px;
                font-weight: 700;
                letter-spacing: 1.2px;
              ">分步训练</p>
            <p class="p_itemStyle" style>手把手分解动作指导，关键点位主动刹车讲解要领</p>
            <p class="p_itemStyle" style="margin-top: 30px">教学方法可视化展示，多视角自动切换，模拟驾驶员看点操作</p>
            <p class="p_itemStyle" style="margin-top: 30px">科目拆解，左倒库/右倒库/完整倒库拆分训练，循序渐进</p>
          </div>
          <div class="card" style="width: 200px">
            <img src="../assets/page5_1.png" alt style="margin-top: 50px; width: 50px" />
          </div>
          <div class="card" style="width: 320px">
            <div class="title_card">
              <p style="
                  text-align: left;
                  font-size: 36px;
                  font-weight: 700;
                  letter-spacing: 1.8px;
                ">STEP3</p>
              <p style="
                  margin-top: 16px;
                  text-align: left;
                  font-size: 24px;
                  font-weight: 300;
                  letter-spacing: 1.2px;
                ">模拟考试</p>
              <p style="
                  margin-top: 4px;
                  text-align: left;
                  font-size: 28px;
                  font-weight: 700;
                  letter-spacing: 1.4px;
                ">实车模拟、智能评判</p>
            </div>
            <p class="p_itemStyle" style="margin-top: 80px">科目考试通用及专项评判规则全覆盖</p>
            <p class="p_itemStyle" style="margin-top: 30px">扣分详情试试记录展示，考试结果智能统计</p>
          </div>
        </div>
      </div>
      <div class="section section_6">
        <h1 class="main_title">安全防护</h1>
        <div class="content">
          <img src="../assets/page6_1.png" alt style="width: 338px; height: 408px" />
          <div class="card" style="position: absolute; top: -50px; left: 0">
            <div class="card_title">
              <p>多场景障碍物识别</p>
            </div>
            <p class="p_itemStyle">配备超声波雷达，毫米波雷达等车规级传感器，实现人、车、物的动、精、远、近全域障碍物探测识别</p>
          </div>

          <div class="card" style="position: absolute; top: 270px; left: 0">
            <div class="card_title">
              <p>毫秒级安全刹车响应</p>
            </div>
            <p class="p_itemStyle">刹车系统响应速度快，缓刹实车体验平稳，实现分级刹停控制策略，保障训练安全同时进一步提升教学效率</p>
          </div>

          <div class="card" style="position: absolute; top: 0px; right: 0px">
            <div class="card_title">
              <p>危险场景识别</p>
            </div>
            <p class="p_itemStyle">车辆超速、溜坡、触碰电子围栏、碰撞障碍物等各种危险场景高精度实时监测，并主动触发降速，缓刹、报警灯分级别安全控制，实现负责场景下的多重安全保障</p>
          </div>

          <div class="card" style="position: absolute; top: 340px; right: 0px">
            <div class="card_title">
              <p>溜坡刹车保护</p>
            </div>
            <p class="p_itemStyle">车辆在坡道及非坡道场景下，可分别实现溜坡识别，一旦感知到异常，车辆自动刹车，保护安全</p>
          </div>
        </div>
      </div>
      <div class="section section_7">
        <h1 class="main_title">智能监管主要功能</h1>
        <h3 class="main_describe">学员训练状态实时监控</h3>
        <div class="content">
          <img src="../assets/page7_1.png" alt style="width: 968px" />
        </div>
      </div>
      <div class="section section_8">
        <div class="col" id="left">
          <div class="text">
            <p>招商代理</p>
            <p>电话: 400-811-569</p>
            <p>邮箱: hykj0012@163.com</p>
          </div>
          <img src="../assets/page8_1.jpg" alt srcset />
        </div>
        <div class="col" id="center">
          <div class="text">
            <p>商务合作</p>
            <p>电话: 400-811-569</p>
            <p>邮箱: hykj0012@163.com</p>
          </div>
          <img src="../assets/page8_3.png" alt srcset />
        </div>
        <div class="col" id="right">
          <img src="../assets/page8_2.png" alt />
          <a id="recordCode" href="https://beian.miit.gov.cn">冀ICP备2022024581号-1</a>
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13010402002367" style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px"
            ><img src="../assets/gov.png" style="float: left; width: 20px; height: 20px" />
            <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: #939393">冀公网安备 13010402002367号</p></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import AnchorPoints from "../components/AnchorPoints.vue";
import { useKeyClickListen } from "../hooks/useKeyClickListen";
import { useScrollListen } from "../hooks/useScrollListen";
import { throttle } from "../utils/throttle";
import { gsap } from 'gsap'

const videoRef = ref(null);

/** 滚动视窗样式，通过vh控制翻页 */
const scrollDivStyle = reactive({
  transform: "translateY(-0vh)",
});

/** 总页数，对应左侧圆点导航点的个数 */
const totalPages = 9;
/** 当前页码索引 */
const nowActiveAnchor = ref(0);
/** 每个section所需的参数 */
const pageParams = reactive([
  {
    showAnimate: false,
    height: 100,
  },
  {
    showAnimate: false,
    height: 100,
  },
  {
    showAnimate: false,
    height: 100,
  },
  {
    showAnimate: false,
    height: 100,
  },
  {
    showAnimate: false,
    height: 100,
  },
  {
    showAnimate: false,
    height: 100,
  },
  {
    showAnimate: false,
    height: 100,
  },
  {
    showAnimate: false,
    height: 100,
  },
  {
    showAnimate: false,
    height: 30,
  },
]);

let { callback: scrollCb } = useScrollListen(".mainPage");
scrollCb.pagedownCb = () => {
  changePage_throttle(true);
};
scrollCb.pageupCb = () => {
  changePage_throttle(false);
};
let { callback: keydownCb } = useKeyClickListen(".mainPage")
keydownCb.pagedownCb = () => {
  changePage_throttle(true);
};
keydownCb.pageupCb = () => {
  changePage_throttle(false);
};

onMounted(() => {
  videoRef.value.addEventListener("canplaythrough", (e) => {
    startAnimate(0)
  });
});


// ---------------------------------

/**
 * 移除所有section的动画class
 */
function removeAllAnimate() {
  pageParams.forEach((item) => {
    item.showAnimate = false;
  });
}
/**
 * 添加指定section的动画class
 * @param {number} targetPageIndex
 */
function startAnimate(targetPageIndex) {
  pageParams[targetPageIndex].showAnimate = true;
}
/**
 * 跳转到指定页
 * @param {number} targetIndex - 目标页索引
 * @param {()=>void} [endCb] - 翻页完毕后的回调
 * @param {number} [throttleTime] - 节流时间，默认800ms
 */
function changePage(targetIndex, endCb, throttleTime = 800) {
  let timer = null;
  nowActiveAnchor.value = targetIndex;

  scrollDivStyle.transform = `translateY(-${pageParams[targetIndex].height + 100 * (targetIndex - 1)}vh)`;

  removeAllAnimate();
  clearTimeout(timer);
  timer = setTimeout(() => {
    startAnimate(targetIndex);
    endCb && endCb();
  }, throttleTime);
}
/**
 * 下一页
 * @param {()=>void} cb - 翻页完毕后的回调
 */
function nextPage(cb) {
  if (nowActiveAnchor.value < totalPages - 1) {
    nowActiveAnchor.value++;
    changePage(nowActiveAnchor.value, cb);
  } else {
    cb && cb();
  }
}
/**
 * 上一页
 * @param {()=>void} cb - 翻页完毕后的回调
 */
function lastPage(cb) {
  if (nowActiveAnchor.value > 0) {
    nowActiveAnchor.value--;
    changePage(nowActiveAnchor.value, cb);
  } else {
    cb && cb();
  }
}
/**
 * 换页的节流函数
 * @param {boolean} isNext - 是否下一页
 */
const changePage_throttle = throttle((isNext) => {
  if (isNext) {
    nextPage();
  } else {
    lastPage();
  }
}, 800);

</script>

<style scoped lang="scss">
@import "@/css/params/color.scss";

.mainPage {
  // user-select: none;
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
  background-image: url("../assets/bg.png");
  background-size: cover;

  .AnchorPointsDiv {
    position: absolute;
    z-index: 1;
    left: 10px;
    top: calc(50% - 108px);
  }

  .nav {
    position: absolute;
    width: 100%;
    z-index: 1;
    color: white;

    ul {
      width: 80%;
      height: 60px;
      margin: 0 auto;

      li {
        float: left;
        list-style: none;
        line-height: 60px;

        &:not(:first-child) {
          margin-left: 40px;
        }

        &:hover {
          a {
            transition: 0.5s;
            text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
            color: wheat;
            cursor: pointer;
          }
        }

        a {
          color: white;
        }
      }
    }
  }

  .scrollDiv {
    transition: 0.8s;
    height: 100%;
    width: 100%;

    .section {
      width: 100%;
      height: 100%;
      position: relative;
      padding: 3% 48px 24px 48px;
      overflow: hidden;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .main_title {
        font-size: 70px;
        letter-spacing: 3.5px;
      }

      .main_describe {
        margin-top: 40px;
        font-size: 24px;
        letter-spacing: 1.2px;
        font-weight: 400;
      }
    }

    .section_0 {
      display: flex;
      position: relative;

      .bgVideo {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        video {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .modal {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.4);
        }
      }

      .content {
        height: 100%;
        width: 100%;
        position: relative;

        .centerContent {
          text-align: center;
          max-height: 100%;
          margin-top: 60px;

          .title_item {
            color: white;
            opacity: 0;

            h1 {
              text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
            }
          }

          .title_item_moved_top_200 {
            animation: titleMove_top_200 0.5s ease-out;
            animation-fill-mode: forwards;
          }

          .title_item_moved_top_300 {
            animation: titleMove_top_300 0.5s ease-out;
            animation-fill-mode: forwards;
          }

          .title_item_moved_bottom {
            animation: titleMove_bottom 1s ease-out;
            animation-fill-mode: forwards;
            animation-delay: 800ms;
          }
        }
      }
    }

    .section_1 {
      text-align: center;

      .item_cards {
        position: relative;
        display: flex;
        width: 1400px;
        margin: 136px auto 0 auto;

        .item_card {
          width: 20%;
          text-align: center;

          .br_line {
            position: relative;
            height: 60px;

            .point {
              position: absolute;
              top: calc(50% - 7px);
              left: calc(50% - 7px);
              border-radius: 100%;
              width: 14px;
              height: 14px;
              background-color: $color-orange;
            }

            .left_line {
              position: absolute;
              width: calc(50% - 12px);
              top: calc(50% - 1px);
              left: 0px;
              height: 2px;
              background-color: rgba(255, 255, 255, 0.1);
            }

            .right_line {
              position: absolute;
              width: calc(50% - 12px);
              top: calc(50% - 1px);
              right: 0px;
              height: 2px;
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }

    .section_2 {
      text-align: center;

      .item_cards {
        position: relative;
        display: flex;
        width: 1400px;
        margin: 70px auto 0 auto;

        .item_card {
          width: 25%;
          text-align: center;

          .bottom_main_title {
            font-size: 26px;
            letter-spacing: 1.3px;
            margin: 33px auto 0 auto;
            font-weight: 700;
          }

          .bottom_describe {
            font-size: 22px;
            letter-spacing: 1.1px;
            margin: 10px auto 0 auto;
            width: 80%;
          }
        }
      }
    }

    .section_3 {
      text-align: center;

      .content {
        margin-top: 100px;
        display: flex;
        justify-content: center;

        .card {
          >.title {
            margin: 0 auto;
            width: 165px;
            height: 62px;
            background-color: $color-orange;
            border-radius: 31px;
            font-size: 26px;
            font-weight: 700;
            line-height: 62px;
          }

          .desctibe_items {
            background-color: #0c1b45;
            border-radius: 18px;
            margin-top: 20px;
            padding: 20px;
          }
        }

        #card_left {
          width: 244px;
        }

        #card_right {
          width: 1025px;
          margin-left: 159px;

          .desctibe_items {
            display: grid;
            grid-template-columns: 50% 50%;

            .desctibe_item {
              padding: 6px;

              .title {
                font-size: 26px;
                font-weight: 700;
                text-align: left;
              }

              .items {
                margin-top: 25px;
                margin-bottom: 25px;
              }
            }
          }
        }
      }
    }

    .section_4 {
      text-align: center;

      .content {
        margin-top: 80px;
        display: flex;
        justify-content: center;

        .card {
          .title {
            font-size: 36px;
            font-weight: 700;
            text-align: left;
            letter-spacing: 1.8px;
          }

          .items {
            margin-top: 32px;
          }
        }
      }
    }

    .section_5 {
      text-align: center;

      .content {
        width: 100%;
        margin-top: 80px;
        display: flex;
        justify-content: center;

        .card {
          height: 500px;

          .title_card {
            padding: 20px 25px;
            width: 100%;
            height: 168px;
            background-color: $color-orange;
            border-radius: 17px;
          }
        }
      }
    }

    .section_6 {
      text-align: center;

      .content {
        width: 1350px;
        margin-top: 80px;
        position: relative;
        display: flex;
        justify-content: center;

        .card {
          width: 420px;

          .card_title {
            display: flex;
            margin-bottom: 10px;

            p {
              background-color: $color-orange;
              font-size: 30px;
              border-radius: 8px;
              padding: 0 14px;
              font-weight: 700;
            }
          }
        }
      }
    }

    .section_7 {
      text-align: center;

      .content {
        width: 1350px;
        margin-top: 80px;
        position: relative;
        display: flex;
        justify-content: center;
      }
    }

    .section_8 {
      background: #0d1932;
      position: relative;
      top: -1px;
      height: calc(30vh + 1px);
      padding: 0px 100px;
      display: flex;
      flex-direction: row;

      .col {
        height: 100%;

        .text {
          width: 300px;
        }

        img {
          margin-left: 30px;
        }
      }

      .col#left,
      .col#center {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-left: 100px;

        p {
          position: relative;
          font-size: 20px;
          letter-spacing: 0.4px;
        }

        p:first-child {
          font-size: 24px;
          letter-spacing: 0.45px;
          margin-bottom: 50px;
        }

        p:first-child:before {
          content: "";
          position: absolute;
          top: 40px;
          height: 1px;
          width: 200px;
          background-color: #1c2a49;
        }

        img {
          width: 137px;
          height: 137px;
        }
      }

      .col#right {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        margin-left: 100px;

        img {
          width: 109px;
          height: 88px;
        }

        p {
          margin-top: 20px;
        }

        #recordCode {
          color: white;
          margin-top: 20px;
        }
      }
    }
  }
}

//------------基础样式库--------------
p.p_itemStyle {
  position: relative;
  font-size: 22px;
  text-align: left;
  line-height: 34px;
  padding-left: 20px;
  font-weight: 300;

  &:before {
    content: "●";
    position: absolute;
    left: 0;
  }
}

@keyframes titleMove_top_200 {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes titleMove_top_300 {
  0% {
    opacity: 0;
    transform: translateY(-300px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes titleMove_bottom {
  0% {
    opacity: 0;
    transform: translateY(300px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
